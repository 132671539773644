import React, { useState } from "react";
import localization from "../localization/localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRestroom,
  faFaucet,
  faDungeon,
  faCampground,
} from "@fortawesome/free-solid-svg-icons";
import "./Trails.css";

const Trails = () => {
  library.add(faRestroom, faFaucet, faDungeon, faCampground);
  const [state, setState] = useState({
    mapLatLon: [60.234598, 22.932896],
    mapZoom: 15,
    markers: [
      {
        latLon: [60.233798, 22.9414],
        size: [30, 42],
        popup: localization.contact.mapmarkers.parking,
        smallerMap: false,
        icon: "parking",
        color: "rgba(0, 131, 143, 1)",
      },
      {
        latLon: [60.234998, 22.940574],
        size: [30, 42],
        popup: localization.contact.mapmarkers.parking,
        smallerMap: false,
        icon: "parking",
        color: "rgba(0, 131, 143, 1)",
      },
      {
        latLon: [60.234198, 22.9406],
        size: [30, 42],
        popup: localization.contact.mapmarkers.fireplace,
        smallerMap: false,
        icon: "fire",
        color: "rgba(255, 186, 57, 0.8)",
      },
      {
        latLon: [60.236599, 22.934],
        size: [30, 42],
        popup: localization.contact.mapmarkers.liftstation,
        smallerMap: false,
        icon: "map-marker-alt",
        color: "rgba(255, 186, 57, 0.8)",
      },
      {
        latLon: [60.232238, 22.9394],
        size: [30, 42],
        popup: localization.contact.mapmarkers.entrance,
        smallerMap: false,
        icon: "dungeon",
        color: "rgba(255, 186, 57, 0.9)",
      },
      {
        latLon: [60.235358, 22.9392],
        size: [30, 42],
        popup: localization.contact.mapmarkers.wash,
        smallerMap: false,
        icon: "faucet",
        color: "rgba(0, 131, 143, 1)",
      },
      {
        latLon: [60.236758, 22.9334],
        size: [30, 42],
        popup: localization.contact.mapmarkers.toilet,
        smallerMap: false,
        icon: "restroom",
        color: "rgba(0, 131, 143, 1)",
      },
      {
        latLon: [60.235128, 22.9398],
        size: [30, 42],
        popup: localization.contact.mapmarkers.reception,
        smallerMap: false,
        icon: "home",
        color: "rgba(255, 186, 57, 0.8)",
      },
      {
        latLon: [60.223198, 22.965974],
        size: [30, 42],
        popup: localization.contact.mapmarkers.endal,
        smallerMap: false,
        icon: "campground",
        color: "white",
      },
      {
        latLon: [60.218398, 22.958574],
        size: [30, 42],
        popup: localization.contact.mapmarkers.teerisaari,
        smallerMap: false,
        icon: "campground",
        color: "white",
      },
      {
        latLon: [60.247398, 22.983974],
        size: [30, 42],
        popup: localization.contact.mapmarkers.kalasuntti,
        smallerMap: false,
        icon: "campground",
        color: "white",
      },
      {
        latLon: [60.216198, 22.933374],
        size: [30, 42],
        popup: localization.contact.mapmarkers.vicksback,
        smallerMap: false,
        icon: "campground",
        color: "white",
      },
    ],
  });

  return (
    <div className="trails-body">
      <div className="trails-map">
        <img alt="trail-map" src={process.env.PUBLIC_URL.concat("/media/Reittikartta.jpg")} />
      </div>
      <div className="trails-us">
        <ul>
          <li>
            <h1>{localization.trails.classes.title}</h1>
          </li>
          <li>
            <p className="trails-us-list-title">
              {localization.trails.classes.green.title + "\n"}
              <FontAwesomeIcon icon="square" />
            </p>
            <a>{localization.trails.classes.green.description}</a>
          </li>
          <li>
            <p className="trails-us-list-title">
              {localization.trails.classes.blue.title + "\n"}
              <FontAwesomeIcon icon="square" />
            </p>
            <a>{localization.trails.classes.blue.description}</a>
          </li>
          <li>
            <p className="trails-us-list-title">
              {localization.trails.classes.red.title + "\n"}
              <FontAwesomeIcon icon="square" />
            </p>
            <a>{localization.trails.classes.red.description}</a>
          </li>
          <li>
            <p className="trails-us-list-title">
              {localization.trails.classes.black.title + "\n"}
              <FontAwesomeIcon icon="square" />
            </p>
            <a>{localization.trails.classes.black.description}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Trails;
